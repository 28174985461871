import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime, TimeTablePeriods} from "../../../resources/constants";
import Select from "react-select";

function ManageTimetable(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showBody, setshowBody] = useState(false)
    const [sessionOptions, setSessionOptions] = useState([]);
    const [semesterOptions, setSemesterOptions] = useState([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
    const [semesterList, setSemesterList] = useState([]);
    const [modules, setModules] = useState([])
    const [courseList, setCourseList] = useState([])
    const header = ["S/N", "Course Name", "Course Code", "Exam Date", "Start Time", "End Time", "Session", "Semester", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        CourseCode: "",
        CourseCodeVal: "",
        Session: "",
        Session2: "",
        Semester: "",
        Semester2: "",
        ExamDate: "",
        StartTime: "",
        EndTime: "",
        _Session: "",
        _Session2: "",
        _Semester: "",
        _Semester2: "",
        InsertedBy: props.loginData[0]?.StaffID,
    })


    const getAllData = async () => {
        await axios.get(`${serverLink}staff/academics/settings/data`)
            .then((result) => {
                let rows = []; let row2 = [];
                if (result.data.message === 'success') {
                    result.data.session.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                        rows.push({ value: item.Session, label: `${item.Session} => ${item.Year} ${item.Status.toString() === "1" ? " (Active Session)" : ""}`})
                    })
                    result.data.courses.map((row) => {
                        row2.push({ value: row.COURSE_CODE, label: row.COURSE_CODE + " - " + row.COURSE_TITLE })
                    });
                    setModules(row2);
                    setSemesterList([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
                    setSessionOptions(rows)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);



    const getTimetable = async (session, semester) => {
        if (semester !== "" && semester !==""){
            await axios.get(`${serverLink}staff/academics/exam-timetable/schedule/list/${btoa(session)}/${semester}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setCourseList(result.data);
                        setshowBody(true)
                    }else{
                        setshowBody(false)
                        toast.error('No record found')
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setshowBody(false)
                    toast.error('NETWORK ERROR')
                });
        }
    }

    const  showTable = () => {
        try {
            return courseList.map((item, index) => {
                const sem = semesterList.length > 0 ? semesterList.filter(x => x.value === item.Semester) : []
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.CourseName}</td>
                        <td className="text-xs font-weight-bold">{item.CourseCode}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.ExamDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{item.StartTime}</td>
                        <td className="text-xs font-weight-bold">{item.EndTime}</td>
                        <td className="text-xs font-weight-bold">{item.Session}</td>
                        <td className="text-xs font-weight-bold">{item.Semester?.toString() ==="1" ? "First" : "Second"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       CourseCode: item.CourseCode,
                                       CourseCodeVal: { value: item.CourseCode, label: item.CourseCode + " - " + item.CourseName },
                                       Session: item.Session,
                                       Session2: { value: item.Session, label: item.Session },
                                       Semester: item.Semester,
                                       Semester2 : { value: sem[0]?.value, label: sem[0]?.label },
                                       ExamDate: item.ExamDate,
                                       StartTime: item.StartTime,
                                       EndTime: item.EndTime,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onModuleChange = (e) => {
        setFormData({
            ...formData,
            CourseCode: e.value,
            CourseCodeVal: e
        })
    }

    const onEditSemester = (e) => {
        setFormData({
            ...formData,
            Semester: e.value,
            Semester2: e,
        });
    };


    const onEditSession = (e) => {
        setFormData({
            ...formData,
            Session: e.value,
            Session2: e,
        });
    };


    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };


    const onSubmit = async () => {

        if (formData.CourseCode === "") {
            showAlert("EMPTY FIELD", "Please select the course", "error");
            return false;
        }
        if (formData.ExamDate === "") {
            showAlert("EMPTY FIELD", "Please enter the exam date", "error");
            return false;
        }
        if (formData.EndTime === "") {
            showAlert("EMPTY FIELD", "Please enter end time", "error");
            return false;
        }
        if (formData.StartTime.toString() === "") {
            showAlert("EMPTY FIELD", "Please enter start time", "error");
            return false;
        }
        if (formData.Session.toString() === "") {
            showAlert("EMPTY FIELD", "Please select session", "error");
            return false;
        }
        if (formData.Semester.toString() === "") {
            showAlert("EMPTY FIELD", "Please select semester", "error");
            return false;
        }
        if (parseInt(formData.EndTime) < parseInt(formData.StartTime)) {
            showAlert("TIME ERROR", "End Time cannot be less than start time", "error");
            return false;
        }
        if (parseInt(formData.EndTime) === parseInt(formData.StartTime)) {
            showAlert("TIME ERROR", "StartTime cannot be same and EndTime", "error");
            return false;
        }
        if (parseInt(formData.EndTime) - parseInt(formData.StartTime)  > 4) {
            showAlert("TIME ERROR", "Exam time cannot be more than four hours", "error");
            return false;
        }

        if (formData.EntryID === "") {
            setIsFormLoading(true);
            await axios.post(`${serverLink}staff/academics/exam-timetable/schedule/add`, formData)
                .then((result) => {
                    // getAllData();
                    if (result.data.message === "success") {
                        getTimetable(formData._Session, formData._Semester)
                        toast.success("timetable created Successfully");
                        setFormData({
                            ...formData,
                            EntryID: "",
                            CourseCode: "",
                            CourseCodeVal: "",
                            Session: "",
                            Session2: "",
                            Semester: "",
                            Semester2: "",
                            ExamDate: "",
                            StartTime: "",
                            EndTime: "",
                        });
                        setIsFormLoading(false);
                        document.getElementById("closeModal").click();
                    } else if (result.data.message === "exists") {
                        setIsFormLoading(false);
                        showAlert("TIMETABLE EXIST", "Timetable already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        } else {
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/academics/exam-timetable/schedule/update`, formData)
                .then((result) => {
                    if (result.data.message === "success") {
                        getTimetable(formData._Session, formData._Semester)
                        toast.success("Timetable created Successfully");
                        setFormData({
                            ...formData,
                            EntryID: "",
                            CourseCode: "",
                            CourseCodeVal: "",
                            Session: "",
                            Session2: "",
                            Semester: "",
                            Semester2: "",
                            ExamDate: "",
                            StartTime: "",
                            EndTime: "",
                        });
                        setIsFormLoading(false);
                        document.getElementById("closeModal").click();
                    }
                    else if (result.data.message === "exists") {
                        setIsFormLoading(false);
                        showAlert("TIMETABLE EXIST", "Timetable already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    };

    const onSemesterChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                _Semester: e.value,
                _Semester2: e
            })
            getTimetable(formData._Session, e.value);
        } else {
            setFormData({
                ...formData,
                _Semester: "",
                _Semester2: ""
            })

        }
    }

    const onSessionChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                _Session: e.value,
                _Session2: e
            })
            getTimetable(e.value, formData._Semester);
        } else {
            setFormData({
                ...formData,
                _Session: "",
                _Session2: ""
            })

        }
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Exam Timetable
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               EntryID: "",
                                               CourseCode: "",
                                               CourseCodeVal: "",
                                               Session: "",
                                               Session2: "",
                                               Semester: "",
                                               Semester2: "",
                                               ExamDate: "",
                                               StartTime: "",
                                               EndTime: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Timetable
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Session and Semester</h5></div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="_Session"
                                                            className="form-select form-select"
                                                            value={formData._Session2}
                                                            onChange={onSessionChange}
                                                            options={sessionOptions}
                                                            placeholder="select Session"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="_Semester"
                                                            className="form-select form-select"
                                                            value={formData._Semester2}
                                                            onChange={onSemesterChange}
                                                            options={semesterOptions}
                                                            placeholder="select Semester"
                                                        />

                                                    </div>
                                                </div>
                                                {
                                                    showBody === true && <DataTable header={header} body={showTable()} title="Exam Timetable Schedule" />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Manage Timetable Form</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Session</label>
                                        <Select
                                            id="Session"
                                            className="form-select form-select"
                                            value={formData.Session2}
                                            onChange={onEditSession}
                                            options={sessionOptions}
                                            placeholder="select Session"
                                        />
                                    </div>

                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Semester</label>
                                        <Select
                                            id="Semester"
                                            className="form-select form-select"
                                            value={formData.Semester2}
                                            onChange={onEditSemester}
                                            options={semesterOptions}
                                            placeholder="select Semester"
                                        />
                                    </div>

                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Course</label>
                                        <Select
                                            name="ModuleCode"
                                            value={formData.CourseCodeVal}
                                            onChange={onModuleChange}
                                            options={modules}
                                            placeholder="select Course"
                                        />

                                    </div>

                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Exam Date</label>
                                        <input
                                            type="date"
                                            id={"ExamDate"}
                                            onChange={onEdit}
                                            value={formData.ExamDate}
                                            className={"form-control"}
                                        />

                                    </div>

                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">Start Time</label>
                                        <select id="StartTime" onChange={onEdit}
                                                value={formData.StartTime}
                                                className="form-select"
                                                data-kt-select2="true"
                                                data-placeholder="Select option"
                                                data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                            <option value={""}>-select start time-</option>
                                            {TimeTablePeriods.length > 0 ?
                                                <>
                                                    {TimeTablePeriods.map((x, y) => {
                                                        return (
                                                            <option key={y} value={x.value}>{x.label}</option>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <></>}
                                        </select>
                                    </div>
                                    <div className="mb-3 form-group col-md-6">
                                        <label className="form-label">End Time</label>
                                        <select id="EndTime" onChange={onEdit}
                                                value={formData.EndTime}
                                                className="form-select"
                                                data-kt-select2="true"
                                                data-placeholder="Select option"
                                                data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                            <option value={""}>-select end time-</option>
                                            {TimeTablePeriods.length > 0 ?
                                                <>
                                                    {TimeTablePeriods.map((x, y) => {
                                                        return (
                                                            <option key={y} value={x.value}>{x.label}</option>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <></>}
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                    Cancel
                                </a>
                                {
                                    IsFormLoading ?
                                        <button type="button" className="btn btn-primary ms-auto">
                                            <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                        </button>
                                        :
                                        <button type="button" onClick={onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageTimetable);
