import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime} from "../../../resources/constants";
import Select from "react-select";

function ExamSeatNoAllocation(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showBody, setshowBody] = useState(false)
    const [sessionOptions, setSessionOptions] = useState([]);
    const [semesterOptions, setSemesterOptions] = useState([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
    const [semesterList, setSemesterList] = useState([]);
    const [modules, setModules] = useState([])
    const [reportList, setReportList] = useState([])
    const header = ["S/N", "Exam Hall", "Seat No", "Student ID", "Student Name", "Course Code", "Course Name", "Session", "Semester", "Attendance", "Generated By", "Generated Date"];
    const [formData, setFormData] = useState({
        EntryID: "",
        CourseCode: "",
        CourseCodeVal: "",
        Session: "",
        Session2: "",
        Semester: "",
        Semester2: "",
        ExamDate: "",
        StartTime: "",
        EndTime: "",
        VenueID: "",
        Capacity: "",
        maxCapacity: "",
        ExamTakers: 0,
        StudentsCount: 0,
        VacantSpace_: 0,
        AssignedStudents: 0,
        InsertedBy: props.loginData[0]?.StaffID,
    })


    const getAllData = async () => {
        await axios.get(`${serverLink}staff/academics/allocation-settings/data`)
            .then((result) => {
                let rows = []; let row2 = [];
                if (result.data.message === 'success') {
                    result.data.session.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                        rows.push({ value: item.Session, label: `${item.Session} => ${item.Year} ${item.Status.toString() === "1" ? " (Active Session)" : ""}`})
                    })
                    result.data.courses.map((row) => {
                        row2.push({ value: row.COURSE_CODE, label: row.COURSE_CODE + " - " + row.COURSE_TITLE })
                    });
                    setModules(row2);
                    setSemesterList([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
                    setSessionOptions(rows)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);

    const getTimetable = async (session, semester) => {
        if (semester !== "" && semester !==""){
            await axios.get(`${serverLink}staff/academics/exam/barcode/list/${btoa(session)}/${semester}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setReportList(result.data)
                        setshowBody(true)
                    }else{
                        setshowBody(true)
                        setReportList([])
                        toast.error('No record found')
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setshowBody(false)
                    toast.error('NETWORK ERROR')
                });
        }
    }

    const  showTable = () => {
        try {
            return reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.HallName}</td>
                        <td className="text-xs font-weight-bold">{item.SeatNo}</td>
                        <td className="text-xs font-weight-bold">{item.StudentID}</td>
                        <td className="text-xs font-weight-bold">{item.StudentName}</td>
                        <td className="text-xs font-weight-bold">{item.CourseCode}</td>
                        <td className="text-xs font-weight-bold">{item.CourseName}</td>
                        <td className="text-xs font-weight-bold">{item.Session}</td>
                        <td className="text-xs font-weight-bold">{item.Semester}</td>
                        <td className="text-xs font-weight-bold">{item.IsPresent?.toString() ==="1" ? "Attended" : "Not Attended"}</td>
                        <td className="text-xs font-weight-bold">{item.StaffName}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const addSchedule = async () => {

        try {

            if (formData.Session.toString() === "") {
                toast.error(`Please Select Session`)
                return;
            }

            if (formData.Semester.toString() === "") {
                toast.error(`Please Select Semester`)
                return;
            }
            setIsFormLoading(true)

            console.log(formData);

            await axios.post(`${serverLink}staff/academics/exam/barcode`, formData).then((result) => {
                let duplicate = result.data.duplicate;
                if (result.data.message === "success") {
                    toast.success("Exam Barcode Generated Successfully");
                    showAlert("BARCODE GENERATED", `Barcode Generated Successfully! \n   ${duplicate.length > 0 ? ` However, barcode has been generated for the following student(s).: ${duplicate}` : ""}  `, "success");
                    getTimetable(formData.Session, formData.Semester);
                } else if (result.data.message === "duplicate") {
                    showAlert("DUPLICATE ENTRIES", `Barcode has been generated for the following student(s), Please check and try again!  \n   ${duplicate.length > 0 ? ` ${duplicate}` : ""}  `, "error");
                } else if (result.data.message === "empty") {
                    showAlert(
                        "ERROR",
                        "No exam timetable schedule for the selected semester!",
                        "error"
                    );
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }

            }).catch((err) => {
                console.log("NETWORK ERROR");
            });

        } catch (e) {
            toast.error("error submitting, please try again");
            setIsFormLoading(false)
        } finally {
            setIsFormLoading(false)
        }
    }

    const onSemesterChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                Semester: e.value,
                Semester2: e
            })
            getTimetable(formData.Session, e.value);
        } else {
            setFormData({
                ...formData,
                Semester: "",
                Semester2: ""
            })

        }
    }

    const onSessionChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                Session: e.value,
                Session2: e
            })
            getTimetable(e.value, formData.Semester);
        } else {
            setFormData({
                ...formData,
                Session: "",
                Session2: ""
            })

        }
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Exam Seat No Allocation
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Session and Semester</h5></div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="Session"
                                                            className="form-select form-select"
                                                            value={formData.Session2}
                                                            onChange={onSessionChange}
                                                            options={sessionOptions}
                                                            placeholder="select Session"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="Semester"
                                                            className="form-select form-select"
                                                            value={formData.Semester2}
                                                            onChange={onSemesterChange}
                                                            options={semesterOptions}
                                                            placeholder="select Semester"
                                                        />

                                                    </div>
                                                </div>
                                                {
                                                    showBody &&
                                                    <>
                                                    <div className="page-header d-print-none">
                                                        <div className="row align-items-center">
                                                        <div className="col-auto ms-auto d-print-none">
                                                            <div className="btn-list">
                                                                {
                                                                    IsFormLoading ?
                                                                        <button type="button" className="btn btn-primary ms-auto">
                                                                            <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                                        </button>
                                                                        :
                                                                        <button type="button" onClick={addSchedule} className="btn btn-primary ms-auto">
                                                                        <span>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                                                                          <path d="M20 11a8.1 8.1 0 0 0 -6.986 -6.918a8.095 8.095 0 0 0 -8.019 3.918"></path>
                                                                          <path d="M4 13a8.1 8.1 0 0 0 15 3"></path>
                                                                          <path d="M19 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                                                          <path d="M5 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                                                          <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                                                        </svg>
                                                                         Generate Exam Seat No
                                                                    </span>
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>

                                                        <div className="col-md-12 mt-4">
                                                            <div className="table-responsive">
                                                                <DataTable header={header} body={showTable()} title="Exam Seat Allocation Report" />
                                                            </div>
                                                        </div></>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ExamSeatNoAllocation);
