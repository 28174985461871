import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDate, formatDateAndTime, TimeTablePeriods} from "../../../resources/constants";
import Select from "react-select";

function ExamTimetableVenueAllocation(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showBody, setshowBody] = useState(false)
    const [sessionOptions, setSessionOptions] = useState([]);
    const [showDate, setshowDate] = useState(false);
    const [showTables, setshowTables] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [semesterOptions, setSemesterOptions] = useState([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
    const [semesterList, setSemesterList] = useState([]);
    const [modules, setModules] = useState([])
    const [reportList, setReportList] = useState([])
    const [timetableList, setTimetableList] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [dates, setDates] = useState([]);
    const [assignedStudentsData, setAssignedStudentsData] = useState([]);
    const [vacantSpace, setVacantSpace] = useState([]);
    const header = ["S/N", "Course Name", "Course Code", "Exam Date", "Start Time", "End Time", "Session", "Semester", "Registered Students", "Assigned Students", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        CourseCode: "",
        CourseCodeVal: "",
        Session: "",
        Session2: "",
        Semester: "",
        Semester2: "",
        ExamDate: "",
        StartTime: "",
        EndTime: "",
        VenueID: "",
        Capacity: "",
        maxCapacity: "",
        ExamTakers: 0,
        StudentsCount: 0,
        VacantSpace_: 0,
        AssignedStudents: 0,
        InsertedBy: props.loginData[0]?.StaffID,
    })


    const getAllData = async () => {
        await axios.get(`${serverLink}staff/academics/allocation-settings/data`)
            .then((result) => {
                let rows = []; let row2 = [];
                if (result.data.message === 'success') {
                    result.data.session.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                        rows.push({ value: item.Session, label: `${item.Session} => ${item.Year} ${item.Status.toString() === "1" ? " (Active Session)" : ""}`})
                    })
                    result.data.courses.map((row) => {
                        row2.push({ value: row.COURSE_CODE, label: row.COURSE_CODE + " - " + row.COURSE_TITLE })
                    });
                    setModules(row2);
                    setSemesterList([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
                    setSessionOptions(rows)
                    setVenueList(result.data.venue)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);



    const getTimetable = async (session, semester) => {
        if (semester !== "" && semester !==""){
            await axios.get(`${serverLink}staff/academics/exam-timetable/schedule/list/${btoa(session)}/${semester}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        let __date = [];
                        result.data.map((x, y) => {
                            __date.push(x.ExamDate)
                        })
                        setDates([...new Set(__date)])
                        setTimetableList(result.data)
                        setshowBody(true)
                    }else{
                        setshowBody(false)
                        setDates([])
                        toast.error('No record found')
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setshowBody(false)
                    toast.error('NETWORK ERROR')
                });
        }
    }

    const  showTable = () => {
        try {
            return reportList.map((item, index) => {
                const sem = semesterList.length > 0 ? semesterList.filter(x => x.value === item.Semester) : []
                const assigned = assignedStudentsData.length > 0 ?
                    assignedStudentsData.filter(x => x.CourseCode === item.CourseCode)[0]?.Students ?? 0 : 0;
                const assigned_to_venues = assignedStudentsData.length > 0 ?
                    assignedStudentsData.filter(x => x.CourseCode === item.CourseCode && x.VenueID === formData.VenueID)[0]?.Students ?? 0 : 0;

                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.CourseName}</td>
                        <td className="text-xs font-weight-bold">{item.CourseCode}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.ExamDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{TimeTablePeriods.filter(x => x.value.toString() === item.StartTime.toString())[0].label}</td>
                        <td className="text-xs font-weight-bold">{TimeTablePeriods.filter(x => x.value.toString() === item.EndTime.toString())[0].label}</td>
                        <td className="text-xs font-weight-bold">{item.Session}</td>
                        <td className="text-xs font-weight-bold">{item.Semester?.toString() ==="1" ? "First" : "Second"}</td>
                        <td className="text-xs font-weight-bold">{item.StudentsCount}</td>
                        <td className="text-xs font-weight-bold">{assigned}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       StudentsCount: 0,
                                       ExamTakers: 0,
                                       VacantSpace_: 0
                                   })
                                   setIsSubmitting(false)
                                   let vacant_spaces =  vacantSpace.length > 0 ?
                                       vacantSpace.filter(x => parseInt(x.VenueID) === parseInt(formData.VenueID) &&
                                           x.StartTime.toString() === item.StartTime.toString() &&
                                           x.EndTime.toString() === item.EndTime.toString()
                                       )[0]?.Vacant ?? formData.Capacity : formData.Capacity;

                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       CourseCode: item.CourseCode,
                                       CourseCodeVal: { value: item.CourseCode, label: item.CourseCode + " - " + item.CourseName },
                                       Session: item.Session,
                                       Session2: { value: item.Session, label: item.Session },
                                       Semester: item.Semester,
                                       Semester2 : { value: sem[0]?.value, label: sem[0]?.label },
                                       ExamDate: item.ExamDate,
                                       StartTime: item.StartTime,
                                       EndTime: item.EndTime,
                                       StudentsCount: parseInt(item.StudentsCount),
                                       ExamTakers: parseInt(item.StudentsCount),
                                       VacantSpace_: vacant_spaces,
                                       AssignedStudents: assigned,
                                   });
                               }}>
                                Assign Hall
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const getTimeTable = async (date) => {
        setIsLoading(true)
        const rows__ = [];
        const by_date = timetableList.filter(x => x.ExamDate.split("T")[0] === date);
        setFormData({
            ...formData,
            ExamDate: date
        });
        const { data } = await axios.get(`${serverLink}staff/academics/exam-timetable/assigned-students/${btoa(formData.Session)}/${formData.Semester}`)
        setAssignedStudentsData(data);

        try {
            await axios.get(`${serverLink}staff/academics/exam-timetable/exam-venue/vacant_space/${date}/${btoa(formData.Session)}/${formData.Semester}`)
                .then((result) => {
                    let rows = [];
                    setVacantSpace(result.data)
                    setReportList(by_date)
                    setshowTables(true)
                    setIsLoading(false)
                    return;
                })
        } catch (e) {
            console.log(e)
        }
    }

    const onEdit = async (e) => {
        if (e.target.id === "VenueID") {
            if (e.target.value !== "") {
                const selectedVenue = venueList.find(x => x.EntryID.toString() === e.target.value.toString());
                setFormData({
                    ...formData,
                    Capacity: selectedVenue.Capacity,
                    maxCapacity: selectedVenue.Capacity,
                    VenueID: e.target.value,
                    rows: [],  // Resetting rows here to avoid multiple state updates
                });
                setshowDate(true); // Updated to camelCase
            } else {
                setshowDate(false);
                setFormData({
                    ...formData,
                    VenueID: "",
                    Capacity: "",
                    maxCapacity: "",
                });
                setshowTables(false);
            }
            return;
        } else if (e.target.id === "ExamDate") {
            setFormData({
                ...formData,
                ExamDate: e.target.value || "",  // Set to empty string if no value
            });
            if (!e.target.value) setshowTables(false);
        } else if (e.target.id === "Capacity") {
            setFormData({
                ...formData,
                Capacity: e.target.value,
            });
            return;
        }
    };


    const SearchExams = () => {
        getTimeTable(formData.ExamDate)
    }


    const addSchedule = async () => {
        setIsSubmitting(true)
        try {

            if (formData.ExamTakers.toString() === "0") {
                toast.error(`Number of students cannot be zero`)
                return;
            }
            if (formData.VacantSpace_ <= 0) {
                toast.error("There are no more spaces in this hall")
                return;
            }
            if (formData.ExamTakers > formData.Capacity) {
                toast.error(`Number of students cannot be more than hall capacity`)
                return;
            }
            if (formData.ExamTakers > formData.StudentsCount) {
                toast.error(`Number of students cannot be more than registered students`)
                return;
            }

            const senData = {
                CourseCode: formData.CourseCode,
                StartTime: formData.StartTime,
                EndTime: formData.EndTime,
                ExamDate: formData.ExamDate,
                Capacity: formData.Capacity,
                ExamTakers: formData.ExamTakers,
                VenueID: formData.VenueID,
                InsertedBy: props.loginData[0].StaffID,
                TimeTableID: formData.EntryID,
                Semester: formData.Semester,
                Session: formData.Session,
                RegisteredStudents: formData.StudentsCount,
            }

            const dt = assignedStudentsData?.filter(x => x.CourseCode === senData.CourseCode)

            if (parseInt(dt[0]?.Students) >= parseInt(dt[0]?.RegStudents)) {
                toast.error(`All students offering ${dt[0]?.CourseCode} have been assigned`)
                return;
            }

            if (parseInt(formData.ExamTakers) + parseInt(formData.AssignedStudents) > parseInt(formData.StudentsCount)) {
                toast.error(`Number of students would exceeds registered students`)
                return;
            }

            await axios.post(`${serverLink}staff/academics/exam-timetable/exam-venue/add`, senData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success('Venue assigned successfully')
                        getTimeTable(formData.ExamDate);
                        document.getElementById("closeModal").click();
                    }
                    else if (result.data.message === "no space") {
                        toast.error('Hall is occupied at this time')
                    }
                    else if (result.data.message === "all students assigned") {
                        toast.error(`All students offering ${formData.CourseCode} have been assigned already`)
                    }
                    setIsSubmitting(false)
                })
        } catch (e) {
            toast.error("error submitting, please try again");
            setIsSubmitting(false)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onSemesterChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                Semester: e.value,
                Semester2: e
            })
            getTimetable(formData.Session, e.value);
        } else {
            setFormData({
                ...formData,
                Semester: "",
                Semester2: ""
            })

        }
    }

    const onSessionChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                Session: e.value,
                Session2: e
            })
            getTimetable(e.value, formData.Semester);
        } else {
            setFormData({
                ...formData,
                Session: "",
                Session2: ""
            })

        }
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Exam Timetable Venue Allocation
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Session and Semester</h5></div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="Session"
                                                            className="form-select form-select"
                                                            value={formData.Session2}
                                                            onChange={onSessionChange}
                                                            options={sessionOptions}
                                                            placeholder="select Session"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="Semester"
                                                            className="form-select form-select"
                                                            value={formData.Semester2}
                                                            onChange={onSemesterChange}
                                                            options={semesterOptions}
                                                            placeholder="select Semester"
                                                        />

                                                    </div>
                                                </div>
                                                {
                                                    showBody === true && <div className="row">
                                                        <div className="col-md-3 form-group">
                                                            <label className="VenueID">Venue</label>
                                                            <select id="VenueID" onChange={onEdit}
                                                                    value={formData.VenueID}
                                                                    className="form-select form-select-solid"
                                                            >
                                                                <option value={""}>-select venue-</option>
                                                                {venueList.length > 0 ?
                                                                    <>
                                                                        {venueList.map((x, y) => {
                                                                            return (
                                                                                <option key={y} value={x.EntryID}>{x.VenueName}</option>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    :
                                                                    <></>}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-3 form-group">
                                                            <label className="Capacity">Hall Capacity (<small className="text-danger">max* {formData.maxCapacity}</small>)</label>
                                                            <input
                                                                disabled
                                                                type="number"
                                                                id={"Capacity"}
                                                                onChange={onEdit}
                                                                value={formData.Capacity}
                                                                className={"form-control"}
                                                                max={formData.maxCapacity}
                                                            />
                                                        </div>

                                                        {showDate === true &&
                                                            <>
                                                                <div className="col-md-3 form-group">
                                                                    <label className="ExamDate">Exam Date</label>
                                                                    <select id="ExamDate" onChange={onEdit}
                                                                            value={formData.ExamDate}
                                                                            className="form-select form-select"
                                                                    >
                                                                        <option value={""}>-select date-</option>
                                                                        {dates.length > 0 ?
                                                                            <>
                                                                                {dates.map((x, y) => {
                                                                                    return (
                                                                                        <option key={y} value={x}>{formatDate(x)}</option>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                            :
                                                                            <></>}
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3 form-group">
                                                                    <button type="submit" className="btn  btn-primary w-100 mt-3" onClick={SearchExams}>
                                                                        Search
                                                                    </button>
                                                                </div>
                                                            </>

                                                        }
                                                        
                                                        {
                                                            showTables &&
                                                            <>
                                                                <div className="col-md-12 mt-4">
                                                                    <div className="table-responsive">
                                                                        <DataTable header={header} body={showTable()} title="Exam Timetable Venue Allocation" />
                                                                    </div>
                                                                </div></>
                                                        }
                                                    </div>
                                                }

                                                {/*{*/}
                                                {/*    showBody === true && <DataTable header={header} body={showTables()} title="Exam Timetable Venue Allocation" />*/}
                                                {/*}*/}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{formData.CourseCode}: </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-8">
                                        <label className="fs-3 fw-bold">Maximum Hall Capacity: {formData.VenueID !== "" &&
                                            venueList.filter(x => x.EntryID.toString() === formData.VenueID.toString())[0].Capacity}
                                        </label><br />
                                        <label className="fs-3 fw-bold">{formData?.ModuleCode} Registered Students: {formData.StudentsCount}
                                        </label><br />
                                        <label className="fs-3 fw-bold">{formData?.ModuleCode} Assigned Students: {formData?.AssignedStudents}</label>

                                    </div>
                                    <div className="col-md-4">

                                        <label className="fw-bolder text-success" style={{ float: 'right' }}>
                                            <span>Hall Vacant spaces left</span><br />
                                            <span style={{ fontSize: '50px' }}>
                                    {parseInt(formData.VacantSpace_)}
                                </span>

                                        </label><br />

                                    </div>

                                    <div className="col-md-12 fs-8">
                                        <span className="text-success">Enter students to write exam in this hall.</span><br />
                                        <span className="text-danger">Sitting students must not be more than the hall capacity</span><br />
                                        <span className="text-danger">Sitting students must not be more than number of registered students</span>
                                        <br />
                                    </div>
                                    <div className="mb-3 form-group col-md-12 mt-4">
                                        <label className="form-label">Sitting Students</label>
                                        <input
                                            type="number"
                                            name="ExamTakers"
                                            className="form-control"
                                            id="ExamTakers"
                                            value={formData.ExamTakers}
                                            maxLength="3"
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    [e.target.id]: e.target.value
                                                })
                                            }}
                                            placeholder="Capacity"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                    Cancel
                                </a>
                                {
                                    IsFormLoading ?
                                        <button type="button" className="btn btn-primary ms-auto">
                                            <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                        </button>
                                        :
                                        <button type="button" onClick={addSchedule} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Assign Hall
                                        </span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ExamTimetableVenueAllocation);
