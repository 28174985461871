import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime} from "../../../resources/constants";
import Select from "react-select";

function TimetableReport(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [showBody, setshowBody] = useState(false)
    const [sessionOptions, setSessionOptions] = useState([]);
    const [semesterOptions, setSemesterOptions] = useState([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
    const [semesterList, setSemesterList] = useState([]);
    const [modules, setModules] = useState([])
    const [courseList, setCourseList] = useState([])
    const header = ["S/N", "Course Code", "Course Name", "Exam Date", "Start Time", "End Time", "Session", "Semester", "Hall", "Capacity", "Students"];
    const [formData, setFormData] = useState({
        EntryID: "",
        CourseCode: "",
        CourseCodeVal: "",
        Session: "",
        Session2: "",
        Semester: "",
        Semester2: "",
        ExamDate: "",
        StartTime: "",
        EndTime: "",
        _Session: "",
        _Session2: "",
        _Semester: "",
        _Semester2: "",
        InsertedBy: props.loginData[0]?.StaffID,
    })


    const getAllData = async () => {
        await axios.get(`${serverLink}staff/academics/settings/data`)
            .then((result) => {
                let rows = []; let row2 = [];
                if (result.data.message === 'success') {
                    result.data.session.sort((a, b) => b.Session - a.Session).map((item, index)=>{
                        rows.push({ value: item.Session, label: `${item.Session} => ${item.Year} ${item.Status.toString() === "1" ? " (Active Session)" : ""}`})
                    })
                    result.data.courses.map((row) => {
                        row2.push({ value: row.COURSE_CODE, label: row.COURSE_CODE + " - " + row.COURSE_TITLE })
                    });
                    setModules(row2);
                    setSemesterList([{ value: "1", label: "First" }, { value: "2", label: "Second" }]);
                    setSessionOptions(rows)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);



    const getTimetable = async (session, semester) => {
        if (semester !== "" && semester !==""){
            setIsLoading(true)
            await axios.get(`${serverLink}staff/academics/exam-timetable/report/${btoa(session)}/${semester}`)
                .then((result) => {
                    if (result.data.length > 0) {
                        setCourseList(result.data);
                        setshowBody(true)
                    }else{
                        setshowBody(false)
                        toast.error('No record found')
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    setshowBody(false)
                    toast.error('NETWORK ERROR')
                });
        }
    }

    const  showTable = () => {
        try {
            return courseList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.CourseCode}</td>
                        <td className="text-xs font-weight-bold">{item.CourseName}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.ExamDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{item.StartTime}</td>
                        <td className="text-xs font-weight-bold">{item.EndTime}</td>
                        <td className="text-xs font-weight-bold">{item.Session}</td>
                        <td className="text-xs font-weight-bold">{item.Semester?.toString() ==="1" ? "First" : "Second"}</td>
                        <td className="text-xs font-weight-bold">{item.VenueName}</td>
                        <td className="text-xs font-weight-bold">{item.Capacity}</td>
                        <td className="text-xs font-weight-bold">{item.Students}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onSemesterChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                _Semester: e.value,
                _Semester2: e
            })
            getTimetable(formData._Session, e.value);
        } else {
            setFormData({
                ...formData,
                _Semester: "",
                _Semester2: ""
            })

        }
    }

    const onSessionChange = async (e) => {
        if (e.value !== "") {
            setFormData({
                ...formData,
                _Session: e.value,
                _Session2: e
            })
            getTimetable(e.value, formData._Semester);
        } else {
            setFormData({
                ...formData,
                _Session: "",
                _Session2: ""
            })

        }
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Exam Timetable Report
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Session and Semester</h5></div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="_Session"
                                                            className="form-select form-select"
                                                            value={formData._Session2}
                                                            onChange={onSessionChange}
                                                            options={sessionOptions}
                                                            placeholder="select Session"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-4 form-group">
                                                        <Select
                                                            id="_Semester"
                                                            className="form-select form-select"
                                                            value={formData._Semester2}
                                                            onChange={onSemesterChange}
                                                            options={semesterOptions}
                                                            placeholder="select Semester"
                                                        />

                                                    </div>
                                                </div>
                                                {
                                                    showBody === true && <DataTable header={header} body={showTable()} title="Exam Timetable Report" />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(TimetableReport);
