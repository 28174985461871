import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";

function ManageVenue(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [reportList, setReportList] = useState([])
    const header = ["S/N", "Venue Name", "Capacity", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        VenueName: "",
        Capacity: "",
        InsertedBy: props.loginData[0]?.StaffID,
        UpdatedBy: props.loginData[0]?.StaffID,
    })


    const getAllData = async () => {
        await axios.get(`${serverLink}staff/academics/venue/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                }else {
                    setReportList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    useEffect( () => {
        getAllData();
    }, []);

    const  showTable = () => {
        try {
            return reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.VenueName}</td>
                        <td className="text-xs font-weight-bold">{item.Capacity}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       VenueName: item.VenueName,
                                       Capacity: item.Capacity
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };


    const onSubmit = async () => {

        if (formData.VenueName === "") {
            showAlert("EMPTY FIELD", "Please enter venue name", "error");
            return false;
        }
        if (formData.Capacity === "") {
            showAlert("EMPTY FIELD", "Please enter the venue capacity", "error");
            return false;
        }

        if (formData.EntryID === "") {
            setIsFormLoading(true);
            await axios.post(`${serverLink}staff/academics/venue/add`, formData)
                .then((result) => {
                     if (result.data.message === "success") {
                        toast.success("Venue Added Successfully");
                        setFormData({
                            ...formData,
                            EntryID: "",
                            VenueName: "",
                            Capacity: ""
                        });
                        setIsFormLoading(false);
                        getAllData();
                        document.getElementById("closeModal").click();
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("VENUE EXIST", "Venue already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        } else {
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/academics/venue/update`, formData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Venue Added Successfully");
                        setFormData({
                            ...formData,
                            EntryID: "",
                            VenueName: "",
                            Capacity: ""
                        });
                        setIsFormLoading(false);
                        getAllData();
                        document.getElementById("closeModal").click();
                    }
                    else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("VENUE EXIST", "Venue already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    };

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Exam Venue
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               EntryID: "",
                                               VenueName: "",
                                               Capacity: ""
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Venue
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">

                                                {
                                                    <DataTable header={header} body={showTable()} title="Exam Venue" />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Manage Venue Form</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Venue Name</label>
                                        <input
                                            type="text"
                                            name="VenueName"
                                            className="form-control"
                                            id="VenueName"
                                            value={formData.VenueName}
                                            onChange={onEdit}
                                            placeholder="Venue Name"
                                        />
                                    </div>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Capacity</label>
                                        <input
                                            type="number"
                                            name="Capacity"
                                            className="form-control"
                                            id="Capacity"
                                            value={formData.Capacity}
                                            onChange={onEdit}
                                            placeholder="Capacity"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                    Cancel
                                </a>
                                {
                                    IsFormLoading ?
                                        <button type="button" className="btn btn-primary ms-auto">
                                            <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                        </button>
                                        :
                                        <button type="button" onClick={onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageVenue);
