import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import PracticalCourseForm from "./practical-course-form";

function PracticalCourse(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [courseList, setCourseList] = useState([])
    const header = ["S/N", "Course Name", "Course Code", "Credit Unit", "Level", "Semester", "Department", "Action"];
    const [formData, setFormData] = useState({
        COURSE_ID: "",
        COURSE_TITLE: "",
        COURSE_CODE: "",
        CREDIT_UNIT: "",
        LEVEL: "",
        SEMESTER: "",
        DEPARTMENT_ID: "",
        INSERTED_BY: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })


    useEffect( () => {
        getData();
    }, []);


    const getData = async () => {
        await axios.get(`${serverLink}staff/academics/practical/course/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setCourseList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return courseList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.COURSE_TITLE}</td>
                        <td className="text-xs font-weight-bold">{item.COURSE_CODE}</td>
                        <td className="text-xs font-weight-bold">{item.CREDIT_UNIT}</td>
                        <td className="text-xs font-weight-bold">{item.LEVEL}00</td>
                        <td className="text-xs font-weight-bold">{item.SEMESTER?.toString() ==="1" ? "First" : "Second"}</td>
                        <td className="text-xs font-weight-bold">{item.DepartmentName}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       COURSE_ID: item.COURSE_ID,
                                       COURSE_TITLE: item.COURSE_TITLE,
                                       COURSE_CODE: item.COURSE_CODE,
                                       CREDIT_UNIT: item.CREDIT_UNIT,
                                       LEVEL: item.LEVEL,
                                       SEMESTER: item.SEMESTER,
                                       DEPARTMENT_ID: item.DEPARTMENT_ID,
                                       INSERTED_BY: item.INSERTED_BY,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };

    const onSubmit = async () => {
        if (formData.COURSE_TITLE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter course title", "error");
            return false;
        }

        if (formData.COURSE_CODE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter course code", "error");
            return false;
        }

        if (formData.CREDIT_UNIT.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter course credit unit", "error");
            return false;
        }

        if (formData.DEPARTMENT_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select course department", "error");
            return false;
        }

        if (formData.LEVEL.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select course level", "error");
            return false;
        }

        if (formData.SEMESTER.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select course semester", "error");
            return false;
        }

        if (formData.COURSE_ID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/academics/practical/course/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Course Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            COURSE_ID: "",
                            COURSE_TITLE: "",
                            COURSE_CODE: "",
                            CREDIT_UNIT: "",
                            LEVEL: "",
                            SEMESTER: "",
                            DEPARTMENT_ID: "",
                        });

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/academics/practical/course/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Course Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            COURSE_ID: "",
                            COURSE_TITLE: "",
                            COURSE_CODE: "",
                            CREDIT_UNIT: "",
                            LEVEL: "",
                            SEMESTER: "",
                            DEPARTMENT_ID: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Practical Course
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               COURSE_ID: "",
                                               COURSE_TITLE: "",
                                               COURSE_CODE: "",
                                               CREDIT_UNIT: "",
                                               LEVEL: "",
                                               SEMESTER: "",
                                               DEPARTMENT_ID: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Practical Course
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Practical Course(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Practical Course(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PracticalCourseForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(PracticalCourse);
